import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Image from "next/image";
const CategoryTag = ({
  classes,
  CategoryTagIconSrc,
  CategoryTagIconAlt,
  CategoryTagTitle,
  showIcon,
  selected,
}) => {
  return (
    <div className={classes.CategoryTag}>
      {showIcon && (
        <img src={CategoryTagIconSrc} alt={CategoryTagIconAlt} />
      )}
      {CategoryTagTitle}
    </div>
  );
};

CategoryTag.defaultProps = {
  showIcon: false,
};

CategoryTag.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  CategoryTagIconSrc: PropTypes.string,
  CategoryTagIconAlt: PropTypes.string,
  CategoryTagTitle: PropTypes.string,
  showIcon: PropTypes.bool,
};
export default withStyles(style)(CategoryTag);
