import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import {
  structuredTextPropTypes,
  imagePropTypes,
} from '../../utils/prop-types';
import style from './style';
import { useLayout } from '../../utils/layout-context';
import CustomLink from '../CustomLink';
function WorkItem({ classes, work }) {
  const { data } = work;
  const { layout } = useLayout();
  return (
    <CustomLink link={work}>
      <div className={classes.workItem}>
        <img src={data.featured_image.url} alt={data.featured_image.alt} />
        <div className="featureWorkItemData">
          <div className="featuredInfo">
            <div className="featuredProjectTitle">
              {RichText.asText(data.title)}
            </div>
            {data.project_category && data.project_category.data && (
              <div className="projectCategoryTitle">
                {RichText.asText(data.project_category.data.title)}
              </div>
            )}
          </div>
          <CustomLink link={work}>
            <Button
              className="button"
              variant="outlined"
              color="primary"
              fullWidth
            >
              {RichText.asText(layout.project_learn_more_button_text)}
            </Button>
          </CustomLink>
        </div>
      </div>
    </CustomLink>
  );
}

WorkItem.propTypes = {
  work: PropTypes.shape({
    title: structuredTextPropTypes,
    featuredImage: imagePropTypes,
    projectCategory: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(WorkItem);
