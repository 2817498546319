const styles = (theme) => ({
  CategoryTag: {
    cursor: 'pointer',
    borderRadius: 6,
    backgroundColor: '#f5faff',
    padding: theme.spacing(2),
    fontFamily: 'Raleway,Assistant',
    fontSize: 16,
    fontWeight: 700,
    color: '#4c4c78',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      fontSize: 16,
    },
    "&:hover": {
      backgroundColor: '#4a92cf',
      color: '#fff',
    },
    "& .activeTag": {
      backgroundColor: '#4a92cf',
      color: '#fff',
    },
    '& img': {
      marginLeft: theme.direction === "ltr" ? 0 : 8,
      marginRight: theme.direction === "ltr" ? 8 : 0,
      width: 14,
    },
  },
})
export default styles
