import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import style from './style'
import { structuredTextPropTypes } from '../../utils/prop-types'
import WorkItem from '../../components/WorkItem'
import CategoryTag from '../../components/CategoryTag'
import TitleSubtitle from '../../components/TitleSubtitle'
import { useAdditionalData } from '../../utils/additional-data-context'
import CustomLink from '../../components/CustomLink'
import { useLayout } from '../../utils/layout-context'
import { motion } from 'framer-motion'
import {
  vScaleFade,
  vSlideRightFadeCustom,
  vTextFade,
} from '../../utils/animations'

const FeaturedWork = ({ slice, classes }) => {
  const { primary, items } = slice
  const additionalData = useAdditionalData()
  const { layout } = useLayout()

  const viewport = {
    once: true,
    margin: '-6%',
  }

  return (
    <Container maxWidth="lg" className={classes.FeaturedWork}>
      <motion.div
        variants={vTextFade}
        initial={'offscreen'}
        whileInView={'onscreen'}
        viewport={viewport}
        transition={{
          type: 'tween',
          delay: 0.5,
          duration: 0.8,
        }}
      >
        <TitleSubtitle
          title={RichText.asText(primary.title)}
          subtitle={RichText.asText(primary.subtitle)}
        />
      </motion.div>
      {primary.showAllCategories && additionalData.project_category && (
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          className="CategoryTagWrapper"
        >
          <Grid item>
            <motion.div
              variants={vScaleFade}
              initial={'offscreen'}
              whileInView={'onscreen'}
              viewport={viewport}
            >
              <a style={{ textDecoration: 'none' }}>
                <CategoryTag
                  CategoryTagIconSrc={'/viewAll.svg'}
                  CategoryTagIconAlt={'View All'}
                  CategoryTagTitle={RichText.asText(
                    layout.projects_view_all_button_text
                  )}
                  showIcon
                />
              </a>
            </motion.div>
          </Grid>
          {additionalData.project_category.map((category, index) => (
            <Grid item key={index}>
              <motion.div
                variants={vScaleFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
              >
                <CustomLink link={category}>
                  <CategoryTag
                    CategoryTagIconSrc={category.data.icon.url}
                    CategoryTagIconAlt={category.data.icon.alt}
                    CategoryTagTitle={RichText.asText(category.data.title)}
                    showIcon
                  />
                </CustomLink>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      )}
      {!primary.showAllCategories && (
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
          className="CategoryTagWrapper"
        >
          {primary.featuredWorkCategory1 && primary.featuredWorkCategory1.data && (
            <Grid item xs={12} sm={4} md={3}>
              <motion.div
                variants={vScaleFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
              >
                <CustomLink link={primary.featuredWorkCategory1}>
                  <CategoryTag
                    CategoryTagIconSrc={
                      primary.featuredWorkCategory1.data.icon.url
                    }
                    CategoryTagIconAlt={
                      primary.featuredWorkCategory1.data.icon.alt
                    }
                    CategoryTagTitle={RichText.asText(
                      primary.featuredWorkCategory1.data.title
                    )}
                    showIcon
                  />
                </CustomLink>
              </motion.div>
            </Grid>
          )}
          {primary.featuredWorkCategory2 && primary.featuredWorkCategory2.data && (
            <Grid item xs={12} sm={4} md={3}>
              <motion.div
                variants={vScaleFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
              >
                <CustomLink link={primary.featuredWorkCategory2}>
                  <CategoryTag
                    CategoryTagIconSrc={
                      primary.featuredWorkCategory2.data.icon.url
                    }
                    CategoryTagIconAlt={
                      primary.featuredWorkCategory2.data.icon.alt
                    }
                    CategoryTagTitle={RichText.asText(
                      primary.featuredWorkCategory2.data.title
                    )}
                    showIcon
                  />
                </CustomLink>
              </motion.div>
            </Grid>
          )}
          {primary.featuredWorkCategory3 && primary.featuredWorkCategory3.data && (
            <Grid item xs={12} sm={4} md={3}>
              <motion.div
                variants={vScaleFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
              >
                <CustomLink link={primary.featuredWorkCategory3}>
                  <CategoryTag
                    CategoryTagIconSrc={
                      primary.featuredWorkCategory3.data.icon.url
                    }
                    CategoryTagIconAlt={
                      primary.featuredWorkCategory3.data.icon.alt
                    }
                    CategoryTagTitle={RichText.asText(
                      primary.featuredWorkCategory3.data.title
                    )}
                    showIcon
                  />
                </CustomLink>
              </motion.div>
            </Grid>
          )}
          {primary.featuredWorkCategory4 && primary.featuredWorkCategory4.data && (
            <Grid item xs={12} sm={4} md={3}>
              <motion.div
                variants={vScaleFade}
                initial={'offscreen'}
                whileInView={'onscreen'}
                viewport={viewport}
              >
                <CustomLink link={primary.featuredWorkCategory4}>
                  <CategoryTag
                    CategoryTagIconSrc={
                      primary.featuredWorkCategory4.data.icon.url
                    }
                    CategoryTagIconAlt={
                      primary.featuredWorkCategory4.data.icon.alt
                    }
                    CategoryTagTitle={RichText.asText(
                      primary.featuredWorkCategory4.data.title
                    )}
                    showIcon
                  />
                </CustomLink>
              </motion.div>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container spacing={4} direction="row" justify="center">
        {!primary.showAllProjects &&
          items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {item.featuredProject && item.featuredProject.data && (
                <motion.div
                  variants={vSlideRightFadeCustom}
                  initial={'offscreen'}
                  whileInView={'onscreen'}
                  viewport={viewport}
                  transition={{
                    type: 'tween',
                    delay: index * 0.2,
                    duration: 0.8,
                  }}
                >
                  <WorkItem work={item.featuredProject} />
                </motion.div>
              )}
            </Grid>
          ))}
        {primary.showAllProjects &&
          additionalData.project.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              {item.data && <WorkItem work={item} />}
            </Grid>
          ))}
      </Grid>
      {!primary.showAllProjects && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="viewAll"
        >
          <CustomLink link={primary.viewAllButtonLink}>
            <motion.div
              variants={vScaleFade}
              initial={'offscreen'}
              whileInView={'onscreen'}
              viewport={viewport}
            >
              <Button variant="contained" color="primary">
                {RichText.asText(primary.viewAllButtonText)}
              </Button>
            </motion.div>
          </CustomLink>
        </Grid>
      )}
    </Container>
  )
}

FeaturedWork.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      title: structuredTextPropTypes,
      subtitle: structuredTextPropTypes,
      featuredWorkCategory1: PropTypes.objectOf(PropTypes.any),
      featuredWorkCategory2: PropTypes.objectOf(PropTypes.any),
      featuredWorkCategory3: PropTypes.objectOf(PropTypes.any),
      featuredWorkCategory4: PropTypes.objectOf(PropTypes.any),
      viewAllButtonText: structuredTextPropTypes,
      viewAllButtonLink: PropTypes.objectOf(PropTypes.any),
      showAllProjects: PropTypes.bool,
      showAllCategories: PropTypes.bool,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        featuredProject: PropTypes.objectOf(PropTypes.any),
      })
    ).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(style)(FeaturedWork)
